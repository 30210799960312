<template>
  <div class="agreementAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'商务合同详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-tabs
        v-model="active"
        sticky
        @change="tabChange"
      >
        <van-tab title="基本信息">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBox"
              @scroll="onScroll"
            >
              <van-collapse
                v-model="activeNames"
                accordion
              >
                <van-collapse-item
                  title="基本信息"
                  name="1"
                >
                  <van-cell
                    center
                    title="合同号:"
                    :value="agreementInfo.agreementCode || '--'"
                  />
                  <van-cell
                    center
                    title="委托单位:"
                    :value="agreementInfo.entrustCompany || '--'"
                  />
                  <van-cell
                    center
                    title="合同类型:"
                    :value="agreementInfo.type || '--'"
                  />
                  <van-cell
                    center
                    title="合同状态:"
                  >
                    <template #default>
                      <span
                        style="color: #00a854"
                        v-if="agreementInfo.status === 0"
                      >激活</span>
                      <span
                        style="color: #d9001b"
                        v-else-if="agreementInfo.status === 1"
                      >睡眠</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="单位地址:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="addressHandler(agreementInfo.companyAddress, agreementInfo.entrustCompany)"
                        v-if="agreementInfo.companyAddress
                        "
                        class="ellipsis"
                      >
                        {{ agreementInfo.companyAddress }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="签订日期:"
                    :value="agreementInfo.signDatetime | openYmdFormat"
                  />
                  <userJump
                    title="签订人:"
                    :userList="agreementInfo.signUser"
                  ></userJump>
                </van-collapse-item>
                <van-collapse-item
                  title="联网单位信息"
                  name="2"
                >
                  <van-cell
                    center
                    title="项目名称:"
                    :value="agreementInfo.projectName || '--'"
                  />
                  <van-cell
                    center
                    title="项目类型:"
                    :value="agreementInfo.projectType || '--'"
                  />
                  <van-cell
                    center
                    title="项目建筑:"
                    :value="agreementInfo.projectBuildingName || '--'"
                  />
                  <van-cell
                    center
                    title="联网单位:"
                    :value="agreementInfo?.internetCompany?.name || '--'"
                  />
                  <van-cell
                    center
                    title="省/市/区:"
                    :value="(agreementInfo?.internetCompany?.location?.province ? agreementInfo.internetCompany.location.province : '') +
                      (agreementInfo?.internetCompany?.location?.city ? '/' + agreementInfo.internetCompany.location.city : '') +
                      (agreementInfo?.internetCompany?.location?.district
                        ? '/' + agreementInfo.internetCompany.location.district
                        : '') || '--'
                      "
                  />
                  <van-cell
                    center
                    title="单位地址:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="addressHandler(agreementInfo.internetCompany.location.province + agreementInfo.internetCompany.location.city + agreementInfo.internetCompany.location.district + agreementInfo.internetCompany.location.address
                          , agreementInfo.internetCompany.name
                        )"
                        v-if="agreementInfo?.internetCompany?.location?.address
                        "
                        class="ellipsis"
                      >
                        {{ agreementInfo.internetCompany.location.address }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="系统类型:"
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        @click="
                          clickDialogHandler('系统类型', agreementInfo.internetCompany.systemType.toString())"
                        v-if="agreementInfo?.internetCompany?.systemType"
                      >点击查看</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
                <van-collapse-item
                  title="其它信息"
                  name="3"
                >
                  <van-cell
                    center
                    title="合同份数（甲方）:"
                    :value="agreementInfo?.other?.firstAgreementNumber || '--'"
                  />
                  <van-cell
                    center
                    title="合同份数（乙方）:"
                    :value="agreementInfo?.other?.secondAgreementNumber || '--'"
                  />
                  <van-cell
                    center
                    title="补充说明:"
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        @click="
                          clickDialogHandler('补充说明', agreementInfo.other.description)"
                        v-if="agreementInfo?.other?.description"
                      >点击查看</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="合同费用">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxYyInfo"
              @scroll="onScrollYyInfo"
            >
              <van-collapse
                v-model="activeIntNames"
                accordion
              >
                <van-collapse-item
                  title="服务范围"
                  name="1"
                >
                  <van-cell
                    center
                    title="服务范围:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="addressHandler(agreementInfo.fee.serviceScope, agreementInfo.fee.serviceScope)"
                        v-if="agreementInfo?.fee?.serviceScope"
                        class="ellipsis"
                      >
                        {{ agreementInfo.fee.serviceScope }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="设备点位数:"
                    :value="agreementInfo?.fee?.equipmentNumber ||
                      '--'
                      "
                  />
                  <van-cell
                    center
                    title="永久服务:"
                  >
                    <template #default>
                      <span
                        style="color: #d9001b"
                        v-if="agreementInfo?.fee?.permanent === 0
                        "
                      >是</span>
                      <span
                        style="color: #00a854"
                        v-else-if="agreementInfo?.fee?.permanent === 1
                        "
                      >否</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="服务期限:"
                    class="more"
                  >
                    <template #default>
                      <div v-if="agreementInfo?.fee?.serviceDatetimeEnd">
                        <span>{{ agreementInfo.fee.serviceDatetimeStart | openYmdFormat }}</span> 到 <span>{{
                          agreementInfo.fee.serviceDatetimeEnd | openYmdFormat }}</span>
                      </div>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="服务时间:"
                  >
                    <template #default>
                      <span v-if="(agreementInfo?.fee?.serviceDay || agreementInfo?.fee?.serviceDay === 0) && agreementInfo?.fee?.permanent === 1">{{
                        agreementInfo.fee.serviceDay }}天</span>
                      <span v-else-if="agreementInfo?.fee?.serviceDay && agreementInfo?.fee?.permanent === 0">{{
                        agreementInfo.fee.serviceDay }}</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
                <van-collapse-item
                  title="合同费用"
                  name="2"
                >
                  <van-cell
                    center
                    title="合同原价:"
                    :value="agreementInfo?.fee?.originalCost | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="缴费方式:"
                    :value="agreementInfo?.fee?.payType || '--'
                      "
                  />
                  <van-cell
                    center
                    title="法定税率:"
                    :value="agreementInfo?.fee?.taxRate || '--'
                      "
                  />
                  <van-cell
                    center
                    title="优惠金额:"
                  >
                    <template #default>
                      <span>{{
                        agreementInfo?.fee?.allPreferential | moneyFormat
                      }}</span>
                    </template>
                  </van-cell>

                  <van-cell
                    center
                    title="合同总价（含税）:"
                    :value="agreementInfo?.fee?.allMoneyHasTax | moneyFormat
                      "
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="折扣"
                  name="3"
                  v-if="agreementInfo?.fee?.discount"
                >
                  <van-cell
                    center
                    title="优惠费用:"
                    :value="agreementInfo.fee.discount.fee ? `${agreementInfo.fee.discount.fee}%` : '--'
                      "
                  />
                  <van-cell
                    center
                    title="优惠原因:"
                    class="more"
                    :value="agreementInfo?.fee?.discount?.reason?.toString() || '--'
                      "
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="优惠"
                  name="4"
                  v-if="agreementInfo?.fee?.preferential"
                >
                  <van-cell
                    center
                    title="优惠费用:"
                    :value="agreementInfo.fee.preferential.fee | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="优惠原因:"
                    class="more"
                    :value="agreementInfo.fee.preferential?.reason?.toString() || '--'
                      "
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="减免"
                  name="5"
                  v-if="agreementInfo?.fee?.derate"
                >
                  <van-cell
                    center
                    title="优惠费用:"
                    :value="agreementInfo.fee.derate.fee | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="优惠原因:"
                    class="more"
                    :value="agreementInfo?.fee?.derate?.reason?.toString() || '--'
                      "
                  />
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="附件">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxXfInfo"
              @scroll="onScrollXfInfo"
            >
              <van-collapse
                v-model="activeOpeNames"
                accordion
              >
                <van-collapse-item
                  title="附件信息"
                  name="1"
                >
                  <van-cell
                    center
                    class="more"
                    title="合同原件:"
                    :url="agreementInfo?.files?.agreementScriptFile?.originalPath ? `/api/file${agreementInfo.files.agreementScriptFile.originalPath}` : ''"
                    :value="agreementInfo?.files?.agreementScriptFile?.name || '--'"
                  >
                    <template #default>
                      <span
                        style="color:#1990ff"
                        v-if="agreementInfo?.files?.agreementScriptFile?.name"
                      >{{
                        `${agreementInfo.files.agreementScriptFile.name}.${agreementInfo.files.agreementScriptFile.ext}
                        `}}</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    class="more"
                    title="报价单:"
                    :url="agreementInfo?.files?.quotationFile?.originalPath ? `/api/file${agreementInfo.files.quotationFile.originalPath}` : ''"
                    :value="agreementInfo?.files?.quotationFile?.name || '--'"
                  >
                    <template #default>
                      <span
                        style="color:#1990ff"
                        v-if="agreementInfo?.files?.quotationFile?.name"
                      >{{
                        `${agreementInfo.files.quotationFile.name}.${agreementInfo.files.quotationFile.ext}
                        ` }}</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="其它附件:"
                    is-link
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        v-if="agreementInfo?.files?.otherFiles"
                        @click="fileHandler(agreementInfo.files.otherFiles)"
                      >{{
                        agreementInfo.files.otherFiles.length }}</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="变更记录">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxChangeList"
              @scroll="onScrollBoxChangeList"
            >
              <van-cell-group
                v-if="agreementInfo.snapshots"
                class="changeList"
              >
                <van-cell
                  v-for="(item, index) in agreementInfo.snapshots"
                  :key="index"
                  @click="changeCardClick(item.id, item.version)"
                >
                  <template #title>
                    <span>{{ item.code }}</span><span style="color: #1990ff">#{{
                      item.version.toString().padStart(2, '0') }}</span>
                  </template>
                  <template #label>
                    <p>变更原因：{{ item?.changeReason?.toString() || '--' }}</p>
                    <div>变更人：
                      <span v-if="item.updateBy && item.updateBy.length">
                        <span
                          :style="{
                            paddingLeft: `${item.updateBy.length > 1 && index1 > 0
                              ? '0.5em'
                              : 'none'
                              }`,
                          }"
                          v-for="(item1, index1) in item.updateBy"
                          :key="index1"
                        >
                          {{ item1.name }}
                        </span>
                      </span>
                      <span v-else>--</span>
                    </div>
                    <p>变更日期：{{ item.updateDatetime | openYmdFormat }}</p>
                  </template>
                </van-cell>
              </van-cell-group>
              <div
                class="empty"
                v-else
              >
                <van-empty
                  image="search"
                  description="暂无相关变更记录信息"
                />
              </div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 其它弹框 -->
    <van-dialog
      v-model="fileShow"
      title="其它附件"
      show-cancel-button
      :show-confirm-button="false"
      cancel-button-color="#1989FA"
      class="fileDialog"
    >
      <van-cell
        :title="`${item.name}.${item.ext}`"
        title-style="color:#1989FA"
        v-for="(item, index) in fileArr"
        :key="index"
        @click="
          fileClick(
            item.ext,
            item
          )
          "
        :url="item.ext === 'pdf' ? '/api/file' + item.originalPath : ''"
      />
    </van-dialog>
  </div>
</template>

<script>
import { Dialog, ImagePreview } from 'vant'
import wx from 'weixin-js-sdk'
import index from '@/mixins'
import { getAgreementInfo } from '@/api/agreement'
import dayjs from 'dayjs'

export default {
  name: 'agreementAddressInfo',
  mixins: [index],
  data() {
    return {
      activeNames: '1',
      activeIntNames: '1',
      activeOpeNames: '1',
      agreementInfo: {},
      loadingShow: false,
      active: 0,
      top: 0,
      topYyInfo: 0,
      topXfInfo: 0,
      topChangeList: 0,
      fileShow: false,
      fileArr: []
    }
  },
  activated() {
    switch (this.active) {
      case 0:
        if (this.$refs.scrollBox) {
          this.$refs.scrollBox.scrollTop = this.top
        }
        break
      case 1:
        if (this.$refs.scrollBoxYyInfo) {
          this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
        }
        break
      case 2:
        if (this.$refs.scrollBoxXfInfo) {
          this.$refs.scrollBoxXfInfo.scrollTop = this.topXfInfo
        }
        break
      case 3:
        if (this.$refs.scrollBoxChangeList) {
          this.$refs.scrollBoxChangeList.scrollTop = this.topChangeList
        }
        break
    }
    if (
      this.$route.params.Refresh
    ) {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getAgreementInfo(this.$route.params.id)
    } else {
      if (JSON.stringify(this.agreementInfo) === '{}') {
        this.getAgreementInfo(this.$route.params.id)
      }
    }
  },
  methods: {
    // 获取详情
    async getAgreementInfo(id) {
      this.loadingShow = true
      try {
        const { data } = await getAgreementInfo(id)
        if (data.data) {
          this.agreementInfo = data.data
          this.active = 0
          wx.onMenuShareAppMessage({
            title: (this.agreementInfo?.internetCompany?.name) || '--', // 分享标题
            desc: `合同号:${this.agreementInfo.agreementCode ? this.agreementInfo.agreementCode : '--'
              }\n签订人:${this.agreementInfo?.signUser[0]?.name
                ? this.agreementInfo?.signUser[0]?.name
                : '--'
              }\n签订日期:${this.agreementInfo.signDatetime
                ? dayjs(this.agreementInfo.signDatetime).format('YYYY-MM-DD')
                : '--'
              }`, // 分享描述
            link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
            imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
            enableIdTrans: 1, // 是否开启id转译，不填默认为0
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            error: function (res) {
              alert('暂无分享权限')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
          this.loadingShow = false
        } else {
          this.loadingShow = false
          if (data?.status === 'E00030007') {
            this.$toast.fail({
              message: '暂无相应权限,请联系管理员!',
              duration: 500,
              onOpened: () => {
                this.$router.replace('/')
              }
            })
            return
          }
          this.$toast.fail({
            message: '该合同已被删除!!',
            duration: 500
          })
        }
      } catch (error) {
        this.loadingShow = false
        if (
          error?.response?.status === 401
        ) {
          return
        }
        if (error.message.includes('timeout')) {
          this.$toast.fail({
            message: '网络不好，请稍后再试!',
            duration: 500
          })
          return
        }
        if (error?.response?.data?.status === 'E00030007') {
          this.$toast.fail({
            message: '暂无相应权限,请联系管理员!',
            duration: 500,
            onOpened: () => {
              this.$router.replace('/')
            }
          })
          return
        }
        this.$toast.fail({
          message: '当前网络异常，请稍后再试!!',
          duration: 500
        })
      }
    },
    // 点击查看弹框
    clickDialogHandler(title, text) {
      Dialog.alert({
        title: title,
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 地址弹框
    addressHandler(text, name) {
      this.addressClickTo(
        text, name)
      Dialog.confirm({
        title: '单位地址',
        message: text,
        confirmButtonColor: '#1989FA',
        confirmButtonText: '去定位',
        beforeClose: this.beforeClose
      })
        .then(() => {
          // on close
        })
        .catch(() => {
          // on cancel
        })
    },
    // 联网单位跳转
    async internetCompanyClick(id) {
      if (id) {
        this.$router.push({
          name: 'companyAddressInfo',
          params: {
            Refresh: true,
            id: id
          }
        })
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 人员跳转
    checkPeopleClick(id) {
      this.$router.push(`/personalAddressInfo/${id}`)
    },
    // 其它附件弹框
    fileHandler(newArr) {
      this.fileArr = newArr
      this.fileShow = true
    },
    // 资质附件点击
    fileClick(ext, itemore) {
      if (ext !== 'pdf') {
        const newArr = this.fileArr
          .filter(item => {
            return item.ext !== 'pdf'
          })
          .map(item1 => {
            return '/api/file' + item1.originalPath
          })
        ImagePreview({
          images: [...newArr],
          closeable: true,
          startPosition: this.fileArr
            .filter(itemFirst => {
              return itemFirst.ext !== 'pdf'
            }).findIndex(itemKey => JSON.stringify(itemKey) === JSON.stringify(itemore))
        })
      }
    },
    // 单位基本信息内容滚动
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 单位联网信息滚动
    onScrollYyInfo(e) {
      this.topYyInfo = e.target.scrollTop
    },
    // 单位开通信息滚动
    onScrollXfInfo(e) {
      this.topXfInfo = e.target.scrollTop
    },
    // 变更记录滚动
    onScrollBoxChangeList(e) {
      this.topChangeList = e.target.scrollTop
    },
    // tabs标签切换
    tabChange(name) {
      switch (name) {
        case 0:
          if (this.$refs.scrollBox) {
            this.$nextTick(() => {
              this.$refs.scrollBox.scrollTop = this.top
            })
          }
          break
        case 1:
          if (this.$refs.scrollBoxYyInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
            })
          }
          break
        case 2:
          if (this.$refs.scrollBoxXfInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxXfInfo.scrollTop = this.topXfInfo
            })
          }
          break
        case 3:
          if (this.$refs.scrollBoxChangeList) {
            this.$nextTick(() => {
              this.$refs.scrollBoxChangeList.scrollTop = this.topChangeList
            })
          }
          break
      }
    },
    // 变更记录点击
    changeCardClick(id, version) {
      this.$router.push({
        name: 'agreementChangeAddressInfo',
        params: {
          id,
          version,
          Refresh: true
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.agreementAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .empty {
      height: 100%;
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .more {
      .van-cell__title {
        flex: 1;
      }

      .van-cell__value {
        flex: 4;
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: hidden;

      .van-tabs__line {
        background-color: #1989fa;
      }

      .van-sticky--fixed {
        top: 46px;
      }

      >.van-tabs {
        height: 100%;

        >.van-tabs__content {
          height: calc(100% - 44px);
          overflow: hidden;

          >.van-tab__pane {
            height: 100%;

            .scrollBox {
              height: 100%;
              overflow: auto;
            }
          }

          .van-collapse {
            background-color: transparent;

            .van-collapse-item {
              background-color: transparent;

              .van-collapse-item__title {
                background-color: white;
                font-weight: 700;
              }

              .van-collapse-item__wrapper {
                background-color: transparent;

                .van-collapse-item__content {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .changeList {
    .van-cell__title {
      background-color: white;

      >span {
        font-weight: 700;
      }
    }
  }

  .fileDialog {
    padding: 10px;
    padding-bottom: 0;
    max-height: 80%;
    overflow: auto;

    .van-dialog__header {
      text-align: left;
      padding: 0;
    }
  }
}
</style>
